<template>
  <EntityList
    :config="config"
    :columns="columns"
    :custom-query="{ congress: congressID }"
    :formatted-columns="formattedColumns"
    :form-fields="formFields"
    authority="Congress_Management_Congress"
  />
</template>

<script>
import EntityList from '@/layouts/entity/EntityList.vue'
import storeModule from './imageStoreModule'

export default {
  components: {
    EntityList,
  },
  computed: {
    congressID() {
      return this.$route.params.id
    },

    config() {
      return {
        store: storeModule,
        module: 'congress-images',
        endpoint: 'congress-images',
        route: 'congress-images',
        title: {
          single: this.$t('Photo Add'),
          plural: this.$t('Photos'),
        },
      }
    },

    columns() {
      return [
        { key: 'ordering', label: this.$t('ordering'), sortable: true },
        { key: 'thumbnail', label: this.$t('Thumbnail'), sortable: false },
        { key: 'title', label: this.$t('Title'), sortable: true },
        {
          key: 'creationDate',
          label: this.$t('Creation Date'),
          sortable: true,
        },
        { key: 'actions', label: this.$t('actions') },
      ]
    },

    formattedColumns() {
      return [{ key: 'thumbnail', type: 'image' }]
    },
    formFields() {
      return [
        {
          id: 'congress',
          type: 'hidden',
          initVal: { id: this.congressID },
          hidden: true,
        },
        {
          id: 'ordering',
          type: 'text',
          label: this.$t('ordering'),
          required: true,
          rules: 'integer',
          colSize: 3,
          initVal: 1,
        },
        {
          id: 'title',
          type: 'text',
          label: this.$t('Title'),
          required: true,
          colSize: 9,
        },
        {
          id: 'img',
          type: 'image',
          label: this.$t('Image'),
          autoupload: true,
        },
        {
          id: 'description',
          type: 'quill',
          label: this.$t('Description'),
          required: false,
        },
      ]
    },
  },
  methods: {
    refetchData() {
      this.$refs.videoList.handleEntitySave()
    },
  },
}
</script>
